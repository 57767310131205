/** @jsx jsx */
// import { jsx, Container, Flex, Box, Text, item, Textarea} from 'theme-ui';
import { jsx, Container, Box, Text} from 'theme-ui';
// import PriceCard from 'components/price-card';
import SectionHeader from 'components/section-header';
// import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
// import PatternBG from 'assets/patternBG.png';
import PatternBG from 'assets/to_top.jpg';
// import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
// import { FaLongArrowAltLeft } from 'react-icons/fa';

// const packages = [
//   {
//     name: 'Free Plan',
//     description: 'For Small teams or office',
//     buttonText: 'Signup Now',
//     points: [
//       {
//         icon: <IoIosCheckmarkCircle />,
//         text: 'Ultimate access to all course, exercises and assessments',
//         isAvailable: true,
//       },
//       {
//         icon: <IoIosCheckmarkCircle />,
//         text:
//           'Free access for all kind of exercise corrections with downloads.',
//         isAvailable: true,
//       },
//       {
//         icon: <IoIosCheckmarkCircle />,
//         text: 'Total assessment corrections with free download access system',
//         isAvailable: true,
//       },
//       {
//         icon: <IoIosCloseCircle />,
//         text: 'Unlimited download of courses on the mobile app contents',
//         isAvailable: false,
//       },
//       {
//         icon: <IoIosCloseCircle />,
//         text: 'Download and print courses and exercises in PDF',
//         isAvailable: false,
//       },
//     ],
//   },
//   {
//     header: 'Recommended',
//     name: 'Premium',
//     description: 'For startup enterprise',
//     priceWithUnit: '$29.99/',
//     buttonText: 'Signup Now',
//     points: [
//       {
//         icon: <IoIosCheckmarkCircle />,
//         text: 'Ultimate access to all course, exercises and assessments',
//         isAvailable: true,
//       },
//       {
//         icon: <IoIosCheckmarkCircle />,
//         text:
//           'Free access for all kind of exercise corrections with downloads.',
//         isAvailable: true,
//       },
//       {
//         icon: <IoIosCheckmarkCircle />,
//         text: 'Total assessment corrections with free download access system',
//         isAvailable: true,
//       },
//       {
//         icon: <IoIosCheckmarkCircle />,
//         text: 'Unlimited download of courses on the mobile app contents',
//         isAvailable: true,
//       },
//       {
//         icon: <IoIosCheckmarkCircle />,
//         text: 'Download and print courses and exercises in PDF',
//         isAvailable: true,
//       },
//     ],
//   },
// ];


// const txt = 'Bitwa, rozpoczęta przez siły rosyjskie 13 sierpnia 1920 r.,\
//  a zakończona 25 sierpnia rozbiciem głównych sił nieprzyjaciela przez\
//   jednostki polskie na wszystkich kierunkach uderzenia i podjęciem skutecznej\
//    kontrofensywy, była przełomowym momentem wojny polsko-bolszewickiej w 1920 r.\
// Wojsko polskie po przegranej bitwie nad Autą (płn. Białoruś, 4-6.07)\
//  z wojskami rosyjskiego Frontu Zachodniego pod dowództwem Michaiła Tuchaczewskiego\
//   wobec dużej przewagi liczebnej i  technicznej przeciwnika nie zdołało zatrzymać Rosjan\
//    na Niemnie ani na Bugu i w ciągu 5 tygodni dotarli oni pod Warszawę. \
// Główne uderzenie nieprzyjaciela mające na celu zdobycie Pragi,\
//  sforsowanie Wisły i zajęcie Warszawy rozpoczęło się atakiem na Radzymin i Ossów.\
//   Mimo przełamania przez Rosjan pierwszej linii obrony i zagrożenia przełamaniem II linii\
//    pod Ossowem i Wólką Radzymińską, ostatecznie po ciężkich 3-dniowych walkach i kosztem\
//     znacznych strat polskie oddziały odparły nieprzyjaciela, odzyskując utracony wcześniej\
//      Radzymin i inne miejscowości.\
// Wielkie znaczenie dla losów bitwy miały też walki nad Wkrą, gdzie 5. Armia pod dowództwem\
//  gen. W. Sikorskiego w krwawych bojach toczonych w dniach 14-18 sierpnia pod Borkowem i Sochocinem,\
//   pod Płońskiem i Sarnową Górą, okupionych dużymi stratami, skutecznie powstrzymała ataki znacznie\
//    przeważającego nieprzyjaciela, wiążąc w walce duże siły rosyjskie i odciążając w ten sposób jednostki\
//     polskie walczące na głównym kierunku bolszewickiej ofensywy pod Radzyminem i Ossowem. \
// Nie powiodły się też podejmowane przez Rosjan próby sforsowania Wisły pod Płockiem i Włocławkiem.\
// Skuteczne powstrzymanie nieprzyjaciela w walkach pod Warszawą zapewniło niezbędny czas na przygotowanie\
//  kontrofensywy wojsk polskich znad Wieprza pod dowództwem Józefa Piłsudskiego,\
//   która doprowadziła do rozbicia głównych sił rosyjskich pod Warszawą.\
//    Wg historyków była to 18. najważniejsza bitwa w dziejach świata.';


export default function Package() {
  // Graphql image query
  // const dataThumb = useStaticQuery(graphql`
  //   query {
  //     placeholderImage: file(relativePath: { eq: "bw1920.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 475) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `);
  
  const data2 = useStaticQuery(graphql`
    query {
      mlawa {    
        allSettings {
          batleDescription
          batleImage{
            publicUrl
          }          
        }
      }
    }
  `);

  // console.log("data2 >>>>>>>", data2);
  // console.log("data2.allSettings.length >>>>>>>", data2.mlawa.allSettings.length);
  // console.log("data2.allSettings.split >>>>>>>", data2.mlawa.allSettings[0].batleDescription.replace(/\<\/p>/g,'').split('<p>'));

  return (
    <section id="historia" sx={styles.package}>
      <Container>
        <SectionHeader
          title="POZYCJA MŁAWSKA"
          slogan=""
          isWhite={true}
        />
        <Box sx={styles.wrapper}>
                {/* <Heading sx={styles.wrapper.title}>{item.title}</Heading> */}
                {/* <Text sx={styles.wrapper.subTitle}>{txt} */}
          <Text sx={styles.wrapper.subTitle}>                
            {/* <Img fluid={dataThumb.placeholderImage.childImageSharp.fluid} sx={styles.img} /> */}
            {data2.mlawa.allSettings.length>0 && data2.mlawa.allSettings[0].batleImage && (
              <img src={data2.mlawa.allSettings[0].batleImage.publicUrl} sx={styles.img} />            
            )}
              {( data2.mlawa.allSettings[0].batleDescription).replace(/\<\/p>/g,'').split('<p>').map((item) =>(
                item.startsWith('<strong>') ? 
                <Text as='p' sx={styles.text.bold}>{item.replace(/\<strong>/g,'').replace(/\<\/strong>/g,'')}</Text>
                : 
                <Text as='p' sx={styles.text}>{item}</Text>
              ))}
            
            {/* {txt} */}
            
          </Text> 
        </Box>
        
        {/* <Flex
          sx={{
            justifyContent: 'center',
            flexWrap: ['wrap', null, null, 'nowrap'],
          }}
        >
          {packages.map((packageData) => (
            <PriceCard data={packageData} key={packageData.name} />
          ))}
        </Flex> */}
      </Container>
    </section>
  );
}

const styles = {
  img: {
    width: ['40%'],
    //height: 'auto',
    flexShrink: 0,
    mr: [3, 4],
    // zIndex:-1,
    float: 'left',
  },
  text:{
    fontSize: [2, 3, 4, '17px', null, 3, '19px', 4],
      bold:{
        fontSize: [2, 3, 4, '17px', null, 3, '19px', 4],
        fontWeight: 'bold'
      }
  },
  thumbnail: {    
    pl: [0, 5, 0, null, 7, 95],
    pr: [0, 5, null, null, null, 75, 95],
    order: [2, null, null, 0],
    width: ['100%', null, 505, 360, 460, 570, null, 670],
    
  },
  package: {
    // backgroundColor: 'primary',
    // backgroundColor: 'heading_secondary',
    backgroundImage: `url(${PatternBG})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    py: [8, null, 9, null, null, 10],
    position: 'relative',
    // '&::before': {
    //   position: 'absolute',
    //   content: '""',
    //   top: 0,
    //   right: 0,
    //   background:
    //     'linear-gradient(-45deg, rgba(42,72,125, 0.3) 25%, transparent 25%, transparent 50%, rgba(42,72,125, 0.3) 50%, rgba(42,72,125, 0.3) 75%, transparent 75%, transparent)',
    //   width: '100%',
    //   backgroundSize: '350px 350px',
    //   height: '100%',
    //   opacity: 0.3,
    //   zIndex: 0,
    //   margin: 'auto',
    // },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    mt: '-5px',
    margin: 'auto',
    title: {
      fontSize: [3, null, 4, null, null, 5],
      color: 'white',
      lineHeight: [1.4, null, null, null, null, 1.55],
      pr: [0, null, null, null, null, 2],
      mb: [2, 3],
    },
    subTitle: {
      fontSize: 1,
      fontWeight: 400,
      lineHeight: [1.85, null, null, 1.9, 2],
      color: 'white',
      // opacity: 0.65,
      pr: [0, null, null, null, null, 5],
    },
  },
};
