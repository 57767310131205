/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Grid, Box, Flex, Heading, Text ,Image} from 'theme-ui';
import SectionHeader from 'components/section-header';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import PatternBG from 'assets/patternBG.png';
import ArrowOdd from 'assets/arrowOdd.png';
import ArrowEven from 'assets/arrowEven.png';

import { Link } from 'components/link';

// const data = [
//   {
//     id: 1,
//     title: 'Pobierz i zainstaluj aplikację.',
//     text:  '',
//   },
//   {
//     id: 2,
//     title: 'Załóż konto lub zaloguj się.',
//     text: '',
//   },
//   {
//     id: 3,
//     title: 'Odwiedzaj miejsca i zbieraj punkty.',
//     text: '',
//   },
//   {
//     id: 4,
//     title: 'Otrzymaj wirtualną odznakę.',
//     text: '',
//   },
// ];

export default function WorkFlow() {

  // Graphql image query
  // const dataThumb = useStaticQuery(graphql`
  //   query {
  //     placeholderImage: file(relativePath: { glob: "*z4.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 400) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `);
  const dataThumb = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(filter: {relativePath: {glob: "*z4.png"}}, sort: {order: ASC, fields: name}) {
        nodes {
          id
          name
          childImageSharp {
            fluid(maxWidth: 500) {              
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  // console.log("dataThumb >>>>>>>> ", dataThumb);

  const slogan="JAK ZDOBYĆ ODZNAKĘ";
  // const title="Wystarczą 4 proste kroki:";
  const title="";
  const isWhite=false;

  return (
    <section sx={styles.workflow} id='kroki'>
      {/* <Container> */}
        {/* <SectionHeader
          slogan="ODZNAKA PTTK Z APLIKACJĄ"
          title="Wystarczą 4 proste kroki:"
          isWhite={false}
        /> */}
        <Box sx={{ variant: 'sectionHeader' }}>
          {slogan !=="" && (
          <Text
            as="p"
            sx={{
              variant: 'sectionHeader.title',
              color: isWhite ? 'white' : 'heading_secondary',
              opacity: isWhite ? 0.7 : 1,
            }}
          >
            {slogan}
          </Text>
          )}
          {title !=="" && (
          <Heading
            as="h2"
            sx={{
              variant: 'sectionHeader.subTitle',
              color: isWhite ? 'white' : 'heading_secondary',
            }}
          >
            {title}
          </Heading>
          )}
          
        </Box>
        <Flex sx={{flexWrap:'wrap', justifyContent: 'center', pr: '150px'}}>
        {/* <Grid> */}
        {dataThumb.placeholderImage.nodes.map((item) =>(
        // <Flex  key={item.id}>
        <Box>
          { item.name === '1z4' ? <Link path="/mobileapp" target="_blank"  key={item.id}> 
            {/* <Img sx={styles.Image} fluid={dataThumb.placeholderImage.childImageSharp.fluid} /> */}
            {/* <Img sx={{ position:'relative', mx:'20%',  display: 'block'}} fluid={dataThumb.placeholderImage.childImageSharp.fluid} /> */}
              <Img sx={{ position:'relative', mx:'20%',  display: 'block', width:'300px'}}  fluid={item.childImageSharp.fluid} />            
            </Link>
            : <Img sx={{ position:'relative', mx:'20%',  display: 'block', width:'300px'}}  fluid={item.childImageSharp.fluid} />  
          }
        </Box>
        // </Flex>
        ))}
        {/* </Grid> */}
        </Flex>        

        {/* <Grid sx={styles.grid}>
          {data.map((item) => (
            <Box sx={styles.card} key={item.id}>
              <Box sx={styles.iconBox}>{`0${item.id}`}</Box>
              <Box sx={styles.wrapper}>
                <Heading sx={styles.wrapper.title}>{item.title}</Heading>
                <Text sx={styles.wrapper.subTitle}>{item.text}</Text>
              </Box>
            </Box>
          ))}
        </Grid> */}
      {/* </Container> */}
    </section>
  );
}

const styles = {
  Image: {
    position: 'ralative',
    mt: '50px',
  },
  workflow: {
    // backgroundColor: 'primary',
    // backgroundColor: 'heading',
    // backgroundImage: `url(${PatternBG})`,
    // backgroundRepeat: `no-repeat`,
    // backgroundPosition: 'center center',
    // backgroundSize: 'cover',
    position: 'relative',
    py: [8, null, 9, null, null, 10],
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      right: 0,
      // background: 'linear-gradient(-45deg, rgba(42,72,125, 0.3) 25%, transparent 25%, transparent 50%, rgba(42,72,125, 0.3) 50%, rgba(42,72,125, 0.3) 75%, transparent 75%, transparent)',
      width: '80%',
      // backgroundSize: '350px 350px',
      // height: '100%',
      // opacity: 0.3,
      // zIndex: 0,
    },
  },
  grid: {
    mb: -1,
    pt: 0,
    gridGap: [
      '35px 0',
      null,
      '45px 50px',
      null,
      '50px 25px',
      null,
      null,
      '50px 65px',
    ],
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(4,1fr)',
    ],
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    textAlign: ['center', null, 'left'],
    width: ['100%', '80%', '100%'],
    mx: 'auto',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: [0, null, null, null, null, 75, null, 100],
      backgroundRepeat: `no-repeat`,
      backgroundPosition: 'center center',
      width: 215,
      height: 60,
      opacity: 0.3,
      '@media screen and (max-width:1220px)': {
        display: 'none',
      },
    },
    '&:nth-of-type(2n-1)::before': {
      backgroundImage: `url(${ArrowOdd})`,
    },
    '&:nth-of-type(2n)::before': {
      backgroundImage: `url(${ArrowEven})`,
      top: 17,
    },
    '&:last-child::before': {
      display: 'none',
    },
  },

  iconBox: {
    width: ['50px', null, '60px', null, null, '70px'],
    height: ['50px', null, '60px', null, null, '70px'],
    flexShrink: 0,
    borderRadius: [15, null, 23, null, null, 30],
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    mb: [5, null, null, null, null, 6],
    mx: ['auto', null, 0],
    fontSize: [6, null, 7, null, null, '30px'],
    fontWeight: 700,
    justifyContent: 'center',
    color: '#234582',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    mt: '-5px',
    title: {
      fontSize: [1, null, 4, null, null, 5],
      // color: 'white',
      color: 'black',
      lineHeight: [1.4, null, null, null, null, 1.55],
      pr: [0, null, null, null, null, 2],
      mb: [2, 3],
    },

    subTitle: {
      fontSize: 3,
      fontWeight: 400,
      lineHeight: [1.85, null, null, 1.9, 2],
      color: 'black',
      opacity: 0.65,
      pr: [0, null, null, null, null, 5],
    },
  },
};
