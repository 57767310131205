/** @jsx jsx */
import { jsx, Container, Box, Grid, Text, Heading, Image, Flex } from 'theme-ui';
import TextFeature from 'components/text-feature';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Briefcase from 'assets/core-feature/briefcase.svg';
import Secure from 'assets/core-feature/secure.svg';
import { Link } from 'components/link';
import SectionHeader from '../components/section-header';

const data = {
  subTitle: '',
  title1: 'ODWIEDZAJ MIEJSCA',
  title2: 'ZBIERAJ PUNKTY',
  title3: 'OTRZYMAJ ODZNAKĘ',
  features: [
    {
      id: 1,
      imgSrc: Briefcase,
      altText: 'Jak zbierać punkty?',
      title: 'Jak zbierać punkty?',
      text:
        'Po zainstalowaniu aplikacji wyszukaj interesujące miejsca, które chcesz odwiedzić. Za każde odwiedzone przez Ciebie miejsce (pamiętaj, musisz mieć włączoną aplikację) otrzymasz 1 punkt.',
      link: '',  
    },
    {
      id: 2,
      imgSrc: Secure,
      altText: 'Odznaki PTTK',
      title: 'Odznaki PTTK',
      text:
        'Zebrane punkty po przekroczeniu odpowiednich progów, zostaną automatycznie wymienione na jedną z kolejnych stopni odznaki.',
      link: '',
    },
    {
      id: 3,
      imgSrc: Secure,
      altText: 'Regulamin',
      title: 'Zapoznaj się z Regulaminem',
      text:
        'Regulamin',
      link: '/termsofuse',
    },
  ],
};

export default function CoreFeature() {
  // Graphql image query
  const dataThumb = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "coreFeature.png" }) {
        childImageSharp {
          fluid(maxWidth: 475) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <section id="odwiedzaj"  sx={styles.coreFeature}>
      <Container sx={styles.containerBox}>
      {/* <SectionHeader
          slogan=''
          title={data.title1}
        /> */}
        <Grid gap="15px 0" columns={1} sx={styles.grid}>
        {/* <Box sx={styles.contentBox}> */}
          {/* <Box sx={styles.headingTop}> */}
          <Box sx={{ 
              variant: 'sectionHeader.title',
              color: 'heading_secondary', 
            }}>
            {/* <TextFeature subTitle={data.subTitle} title={data.title} /> */}
            {/* <Text sx={styles.wrapper.title}>{data.title1} </Text> */}
            {/* <Text sx={styles.headingTop} >{data.title1} </Text> */}
            <Text >{data.title1} </Text>
            <Text >{data.title2} </Text>
            <Text >{data.title3} </Text>
          </Box>
        
        {/* <Grid gap="15px 0" columns={2} sx={styles.card}> */}
        <Flex sx={styles.card}>
          <Box sx={{ width:'500px'}}>
          {/* <Grid gap="15px 0" columns={1} sx={styles.grid}> */}
            {data.features.map((item) => (
              <Box sx={styles.card} key={item.id}>
                {/* <Image src={item.imgSrc} alt={item.altText} sx={styles.img} /> */}

                <Box sx={styles.wrapper}>
                  <Heading sx={styles.wrapper.title}>{item.title}</Heading>
                  { item.link !== '' ?
                  <Link path={item.link} target="_blank">{item.text}</Link>
                  : 
                    <Text sx={styles.wrapper.subTitle}>{item.text}</Text>
                  }
                </Box>
              </Box>
            ))}    
            </Box>      
          {/* </Grid>                */}
        {/* </Grid> */}
        {/* </Flex> */}
        {/* </Box> */}
          {/* <Grid gap="15px 0" columns={2} sx={styles.grid}> */}
          <Box sx={{ width:'500px'}}>
          <Box sx={styles.thumbnail}>          
              <Img sx={{ mt:"5%", minWidth:'200px'}} fluid={dataThumb.placeholderImage.childImageSharp.fluid} />
          </Box>
          </Box>
          {/* </Grid> */}
        {/* </Grid> */}
        </Flex>
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  coreFeature: {
    py: [0, null, null, 2, null, 7],
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: ['auto', null, null, '50%'],
      bottom: ['100px', 0, null, 'auto'],
      left: 0,
      // background: 'linear-gradient(-157deg, #F6FAFD, #F9FCFC, #FCFDFC)',
      height: [350, 550, '60%'],
      width: '70%',
      zIndex: -1,
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      transform: ['translateY(0)', null, null, 'translateY(-50%)'],
      
    },
  },
  containerBox: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    justifyContent: 'space-around',
    flexDirection: ['column', null, null, 'row'],
    // backgroundColor:'heading',
    // background: 'linear-gradient(-45deg, rgba(42,72,125, 0.3) 25%, transparent 25%, transparent 50%, rgba(42,72,125, 0.3) 50%, rgba(42,72,125, 0.3) 75%, transparent 75%, transparent)',
  },
  thumbnail: {
    pl: [0, 5, 0, null, 7, 95],
    pr: [0, 5, null, null, null, 75, 95],
    order: [2, null, null, 0],
    width: ['100%', null, 505, 360, 460, 570, 600, 770],
    pt:'auto',
    pb:'auto',
  },
  contentBox: {
    width: ['100%', 450, 550, 350, 500, 570],
    pr: [0, null, 'auto', null, null, 80],
    pl: 'auto',
    flexShrink: 0,
  },
  headingTop: {
    pl: [0, null, null, null, '35px', null, '55px', 6],
    mb: [3, null, null, null, 1],
    textAlign: ['center', null, null, 'left'],
    fontSize: 12,
    lineHeight: 1.2,
    fontWeight: 700,
  },
  grid: {
    p: ['0 0px 35px', null, null, null, '0 20px 40px', null, '0 40px 40px', 0],
  },
  card: {
    display: 'flex',
    flexWrap:'wrap',
    alignItems: 'flex-start',
    p: [
      '0 17px 20px',
      null,
      null,
      '0 0 20px',
      '20px 15px 17px',
      null,
      null,
      '25px 30px 23px',
    ],
    backgroundColor: 'white',
    borderRadius: '10px',
    transition: 'all 0.3s',
    width: ['100%', '85%', null, '100%'],
    mx: 'auto',
    '&:hover': {
      boxShadow: [
        '0px 0px 0px rgba(0, 0, 0, 0)',
        null,
        null,
        null,
        '0px 8px 24px rgba(69, 88, 157, 0.07)',
      ],
    },
  },

  img: {
    width: ['50px', null, '55px'],
    height: 'auto',
    flexShrink: 0,
    mr: [3, 4],
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    mt: '-5px',
    a: {
      textDecoration: 'none',
      color: 'inherit',
      pl: 1,
      transition: 'all 0.25s',
      '&:hover': {
        color: 'primary',
      },
    },
    title: {
      fontSize: 3,
      color: 'heading_tetriary',
      lineHeight: 1.4,
      fontWeight: 700,
      mb: [2, null, null, null, 3],
    },
    subTitle: {
      fontSize: 1,
      fontWeight: 400,
      lineHeight: [1.85, null, 2],
    },
   
  },
};
