/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Flex, Box, Heading, Text, Button, Image } from 'theme-ui';
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ModalVideo from 'react-modal-video';
import { Link } from 'components/link';
import Img from 'gatsby-image';
import { FaPlayCircle } from 'react-icons/fa';
// import BannerBG from 'assets/bannerBg.png';
import BannerBG from 'assets/to_top.jpg';

import client1 from 'assets/sponsor/paypal.svg';
import client2 from 'assets/sponsor/google.svg';
import client3 from 'assets/sponsor/dropbox.svg';

const data = [
  {
    id: 1,
    path: '#',
    image: client1,
    title: 'paypal',
  },
  {
    id: 2,
    path: '#',
    image: client2,
    title: 'google',
  },
  {
    id: 3,
    path: '#',
    image: client3,
    title: 'dropbox',
  },
];

export default function Banner() {
  // Graphql image query
  const dataThumb = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "banner-thumb.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // Popup video status
  const [videoOpen, setVideoOpen] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setVideoOpen(true);
  };
  return (
    <section sx={styles.banner} id="home">
      <Container sx={styles.banner.container}>
        <Box sx={styles.banner.imageBox}>
          <Img fluid={dataThumb.placeholderImage.childImageSharp.fluid} />
        </Box>

        <Box sx={styles.banner.contentBox}>
          <Heading as="h1" sx={{textAlign: 'center', fontSize: 72}} variant="heroPrimary">
          MŁAWSKI
          </Heading>
          <Heading as="h2" sx={{textAlign: 'center', fontSize: 60}} variant="lead2">
          Szlak Turystyczny
          </Heading>
          {/* <Heading as="h2" sx={{textAlign: 'center'}} variant="lead2">
          Turystyczny
          </Heading> */}
          {/* <Text as="p" sx={{px:"10px", textAlign: 'center', lineHeight: 1.5}} variant="heroSecondary"> */}
          <Text as="p" sx={styles.text}>
            Wytyczony przez Polskie Towarzystwo Turystyczno-Krajoznawcze Oddział „Mazowsze” szlak dedykowany jest przede wszystkim turystyce pieszej i rowerowej, przebiega przez tereny Mławy, gmin Iłowo-Osada, Lipowiec Kościelny, Szydłowo i Wieczfnia Kościelna oraz Nadleśnictwa Dwukoły, utworzony w oparciu o przebieg już istniejącego szlaku walk wrześniowych 1939 roku, wyznaczonego przez PTTK w 1979 r. 
            Najważniejszymi elementami szlaku są znajdujące się wzdłuż jego trasy oryginalne fortyfikacje – w tym schrony bojowe i transzeje – z okresu II wojny światowej. Turyści znajdą na nim także wiele innych atrakcji, w tym m.in. zabytki architektoniczne (np. kościół pw. Świętej Trójcy, ratusz, budynek muzeum i biblioteki publicznej w Mławie), miejsca pamięci (np. kopiec Powstańców Styczniowych w Mławie, cmentarz niemiecki w Mławce, pomnik piechura w Uniszkach Zawadzkich), obiekty rekreacyjne (np. zalew Ruda) i ostoje przyrody (np. rezerwat Dębowa Góra w Dwukołach, Zagroda Żubra w Mławie). 
            Nawierzchnie szlaku są zróżnicowane – począwszy od jezdni asfaltowych, poprzez drogi szutrowe, na polnych ścieżkach skończywszy – w związku z czym proponujemy zaopatrzyć się w dobre buty, wygodny strój i, w przypadku miłośników jednośladów, rower przeznaczony do jazdy górskiej. Wzdłuż szlaku są wyznaczone miejsca na odpoczynek – idealne na krótki popas i złapanie oddechu.
          </Text>
          <Text as="p" sx={styles.text}>
              Zapraszamy na Mławski Szlak Turystyczny!
          </Text>
          {/* <Flex>
            <Button variant="whiteButton" aria-label="Get Started">
              Get Started
            </Button>
            <>
              <ModalVideo
                channel="youtube"
                isOpen={videoOpen}
                videoId="ZNA9rmDsYVE"
                onClose={() => setVideoOpen(false)}
              />
              <Button
                variant="textButton"
                aria-label="Watch Video"
                onClick={handleClick}
              >
                <FaPlayCircle /> Watch Video
              </Button>
            </>
          </Flex> */}
          {/* <Flex sx={styles.sponsorBox}>
            <Text sx={styles.sponsorTitle}>Sponsored by:</Text>
            <Box sx={styles.sponsorBox.sponsor}>
              {data.map((item, index) => (
                <Link path={item.path} key={`client-key${index}`}>
                  <Image src={item.image} alt={item.title} />
                </Link>
              ))}
            </Box>
          </Flex> */}
        </Box>

       
      </Container>
    </section>
  );
}

const styles = {
  text:{
    px:"10px", 
    textAlign: 'center',
    fontSize: [2, 3, 4, '17px', null, 3, '19px', 4],
    lineHeight: 1.6,
    color: 'white',
    
    bold:{
      px:"10px", 
      textAlign: 'center',
      fontSize: [2, 3, 4, '17px', null, 3, '19px', 4],
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: 'white',
    }
  },
  banner: {
    overflow: ['hidden', 'initial', null, 'hidden'],
    backgroundImage: `url(${BannerBG})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: 'top left',
    backgroundSize: 'cover',
    borderBottomRightRadius: [100, 150, null, null, null, 250],
    pt: ['150px', null, null, null, null, null, '140px', '130px'],
    pb: ['100px', null, null, '110px', null, 10, '100px'],
    // backgroundColor: 'primary',
    container: {
      display: 'flex',
      alignItems: 'center',
      // alignItems: 'stretch',
      justifyContent: 'space-between',
      // justifyContent: 'center',
      justifyItems:'stretch',
      flexDirection: ['column-reverse', null, null, 'row'],
      px: '0px',
      // mx: '20px',
    },
    contentBox: {
      // width: ['100%', null, '85%', '55%', '50%', '55%'],
      // display: 'flex',
      // flexDirection: 'column',
      // alignItems: 'flex-start',
      // alignItems: 'center',
      alignItems: 'flex-end',
      flexShrink: 0,
      //pt: [5, null, null, null, null, null, 5, 7],
      // pt: ['150px', null, null, null, null, null, '140px', '130px'],
      width: ['100%', 400, 505, 360, 460, 570, null, 800],
      // width: '50%',
      // pr: [0, null, 'auto', null, null, 80],
      pr: 'auto',
      pl: 'auto',
    },
    imageBox: {
      // display: ['none', null, null, 'block'],
      // justifyContent: 'center',
      // ml: [0, null, null, '-110px', '-115px', '-150px', '-210px', '-270px'],
      // mr: [0, null, null, '-145px', '-160px', '-180px', '-220px', '-290px'],
      // mt: [0, null, null, '40px', 4, 7, 0],
      // mb: [0, null, null, null, '-45px', '-70px', null, '-115px'],
      // overflow: 'hidden',
      // textAlign: 'right',
      // width: '100%',
      order: [2, null, null, 0],
      width: ['100%', null, 505, 360, 460, 570, null, 800],
      // width: '50%',
      // width: ['100%', 450, 550, 350, 500, 570],
    },
  },
  sponsorTitle: {
    color: 'white',
    fontSize: [1, 2],
    opacity: 0.6,
    pr: 20,
    flexShrink: 0,
    pb: [2, null, 0],
  },
  sponsorBox: {
    pt: ['35px', null, null, null, null, '45px'],
    flexDirection: ['column', null, 'row'],
    sponsor: {
      display: 'flex',
      alignItems: 'center',
      '> a': {
        mr: [5, null, null, 4, 6],
        display: 'flex',
        '&:last-of-type': {
          mr: 0,
        },
      },
    },
  },
};
