/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Grid } from 'theme-ui';
import SectionHeader from '../components/section-header';
import FeatureCardColumn from 'components/feature-card-column.js';
import Vector from 'assets/key-feature/vector.svg';
import Editing from 'assets/key-feature/editing.svg';
import Speed from 'assets/key-feature/speed.svg';
import MedalSilver from 'assets/key-feature/silver.png';
import MedalBrown from 'assets/key-feature/brown.png';
import MedalGold from 'assets/key-feature/gold.png';

const data = [
  {
    id: 1,
    imgSrc: MedalBrown,
    altText: 'Brąz',
    title: 'STOPIEŃ BRĄZOWY',
    text: 'Wystarczy że twoje wędrówki doprowadzą Cie do minimum 50 punktowanych miejsc, żeby otrzymać brązową odznakę PTTK.',    
    color: 'brown',
  },
  {
    id: 2,
    imgSrc: MedalSilver,
    altText: 'Srebro',
    title: 'STOPIEŃ SREBRNY',
    text: 'Jeżeli uzbierasz ponad 100 punktów na twoim koncie pojawi się srebrna odznaka PTTK.',
    color: 'silver',
  },
  {
    id: 3,
    imgSrc: MedalGold,
    altText: 'Złoto',
    title: 'STOPIEŃ ZŁOTY',
    text:'Odwiedź 150 miejsc, żeby otrzymać jedyną i niepowtarzalną odznakę w stopniu złotym.',   
    color: '#e4b064', 
  },
];

export default function KeyFeature() {
  return (
    <section sx={{ variant: 'section.keyFeature' }} id="odznaka">
      <Container>
        <SectionHeader
          // slogan='Przedstawiamy pierwszą w Polsce cyfrową odznakę PTTK "MŁAWSKI SZLAK TURYSTYCZNY" i zapraszamy do jej zdobywania. Odznaka jest trzystopniowa. Po jej zdobyciu istnieje możliwość pozyskania fizycznej wersji odznaki. odznakę można pozyskać w siedzibie Oddziału PTTK „Mazowsze” w Warszawie przy ul. Senatorskiej 11 (tel. 22629397), na podstawie okazanego zapisu na własnym urządzeniu elektronicznym zdobywającego odznakę. '
          slogan='PRZEDSTAWIAMY CYFROWĄ ODZNAKĘ PTTK "mławski szlak turystyczny" I ZAPRASZAMY DO JEJ ZDOBYWANIA. ODZNAKA JEST TRZYSTOPNIOWA. PO JEJ ZDOBYCIU od 2023 r. będzie istniała MOŻLIWOŚĆ POZYSKANIA FIZYCZNEJ WERSJI ODZNAKI NA PODSTAWIE OKAZANEGO ZAPISU NA WŁASNYM URZĄDZENIU ELEKTRONICZNYM ZDOBYWAJĄCEGO ODZNAKĘ. o szczegółach poinformujemy.'
          title='ODZNAKA "MŁAWSKI SZLAK TURYSTYCZNY"'
        />

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <FeatureCardColumn
              key={item.id}
              src={item.imgSrc}
              alt={item.title}
              title={item.title}
              text={item.text}
              color={item.color}
            />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    px: [0, null, null, '40px', null, '80px'],
    pt: [0, null, null, null, null, null, null, 3],
    gridGap: ['35px 0', null, '40px 0'],
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(3,1fr)',
    ],
    width: ['100%', '80%', '90%'],
    mx: 'auto',
    '& > div': {
      px: [0, '15px', null, '25px', null, '30px', '40px', '60px'],
    },
  },
};
