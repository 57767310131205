import React from "react";
import { StickyProvider } from "contexts/app/app.provider";
import SEO from "components/seo";
import Layout from "components/layout";
import Banner from "sections/banner";
import Odznaka from "sections/key-feature";
import Odwiedzaj from "sections/core-feature";
// import Feature from "sections/feature";
// import PartnerSection from "sections/partner";
import Kroki from "sections/workflow";
// import TestimonialCard from "sections/testimonial";
// import Proponuj from "sections/secure-payment";
import Bitwa from "sections/package";
// import Faq from "sections/faq";
// import Map from 'map/Map';
// import Map2 from 'map/Map2';
// import Map from "components/Map";
// import LeafletMap from "components/leafletmap";
import Map from "sections/map";

// Load other package css file
import "react-multi-carousel/lib/styles.css";
import "react-modal-video/css/modal-video.min.css";
import "rc-drawer/assets/index.css";
// import "mapbox-gl/dist/mapbox-gl.css";

export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <SEO title="MŁAWSKI SZLAK TURYSTYCZNY" />
        <Banner />        
        <Kroki />
        <Odwiedzaj />        
        <Odznaka />        
        <Map />
          {/* <Proponuj /> */}
        <Bitwa />
          {/* <Feature /> */}
          {/* <PartnerSection /> */}        
          {/* <TestimonialCard /> */}
          {/* <Faq /> */}
      </Layout>
    </StickyProvider>
  );
}
