import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Tooltip, LayersControl,  Polyline } from "react-leaflet";


const LayersTrails=()=>{

  // const color =  {color: 'red'};
  // const weight = {weight: 5};
  const weight = 5;

  return(
    <StaticQuery
      query={graphql`
        query {
          mlawa {                    
            allTrails {     
                  id              
                  uniqNumber   
                  name                      
                  route{
                    coordinates                              
                  }
                  color  
              }                
            }
        }
    `}
    
    render={ data => (
      
      // data.bw.allPOIS.map((index) => (
      //   <Marker position={[index.location.lat, index.location.lng]}>
      //     <Popup>
      //       {index.name}
      //     </Popup>
      //   </Marker>
      // ));
      // console.log("color>>>>>>", data[0].color);
      data.mlawa.allTrails.map((index) => (
        index.route && (
          <LayersControl.Overlay checked name={index.name}>
          {/* // let tab=[];
          // index.route.coordinates.forEach(element => {
          //   // console.log("element>>>>>>>>",element);
          //   tab.push(element[1],element[0]);
          // });

          // console.log("tab>>>>>>>>",tab);
                                
          // <Polyline pathOptions={redOptions} positions={tab} />                    
          // <Polyline pathOptions={redOptions} positions={index.route.coordinates} />                     */}
          {/* <Polyline key={index.uniqNumber}  pathOptions={`{color: ${index.color} }`} positions={index.route.coordinates} > */}
          {/* <Polyline key={index.uniqNumber}  pathOptions= {color, weight } positions={index.route.coordinates} > */}
          <Polyline key={index.uniqNumber}  pathOptions={createOptionColorWeight(index.color, weight)} positions={index.route.coordinates} >

            <Tooltip sticky>{index.name}</Tooltip>
            {/* <Popup> nazwa w Popup</Popup> */}

          </Polyline>

          {/* // <Polyline pathOptions={redOptions} positions={polyline} />           
          // return (<Polyline pathOptions={redOptions} positions={tab} />  );          */}
          </LayersControl.Overlay>      
        )
      )
      )
    )}
    />
  )
}


function createOptionColorWeight(c,w){ 
  return {color: c, weight: w};
}


        
export default LayersTrails;