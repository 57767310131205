/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Flex, Box, Heading, Text, Button, Image } from 'theme-ui';
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import LeafletMap from "components/leafletmap";

export default function Map() {
 
  return (
    <section sx={styles.map} id="mapa">
      {/* <Container sx={styles.map.container}> */}
        <Box sx={styles.map.contentBox}>
          <LeafletMap/>
        </Box>        
      {/* </Container> */}
    </section>
  );
}

const styles = {    
  map: {
    overflow: ['initial', 'initial', null, 'hidden'],
    // backgroundImage: `url(${BannerBG})`,
    // backgroundRepeat: `no-repeat`,
    // backgroundPosition: 'top left',
    // backgroundSize: 'cover',
    // borderBottomRightRadius: [100, 150, null, null, null, 250],
    // pt: ['0px', null, null, null, null, null, '140px', '130px'],
    // pb: ['50px', null, null, '110px', null, 10, '150px'],
    pl: ['0px', null, null, '0px', null, null, '0px'],
    backgroundColor: 'primary',
    width:'100%',
    
    container: {
      // display: 'flex',
      width:'100%',
    },
    contentBox: {
    //   width: ['100%', '100%', '85%', '55%', '50%', '55%'],
        width:'100%',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'flex-start',
        // flexShrink: 0,
        // pt: [5, null, null, null, null, null, 5, 7],
        // pt: ['250px', null, null, null, null, null, '140px', '130px'],
    },
    
  },
 
};
