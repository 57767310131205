import React from "react";
// import PropTypes from "prop-types";
//import { MapContainer, Map, TileLayer, Marker, Popup, LayersControl, LayerGroup, Polyline ,MapConsumer} from "react-leaflet";
import { MapContainer,  TileLayer,  LayersControl } from "react-leaflet";
import './leafletmap.css';
// import { useStaticQuery, StaticQuery, graphql } from "gatsby";
// import { element } from "prop-types";
// import { arrayOf } from "prop-types";
// import { useBwPois } from "../hooks/useBwPois";
import LayersPois from "./LayersPois";
import LayersTrails from "./LayersTrails";

// const styles = {
//     map: {       
//             height: '100%',
//             width: '100%',
//             // pl: '25%',
//             // alignItems: 'flex-start',
//             backgroundColor:'background_secondary',

//     }      
// };


export default function LeafletMap() {

  // console.log("Query>>>>>>>>>>", data);
  // const fillBlueOptions = { fillColor: 'blue' }
  // const blackOptions = { color: 'black' }
  // const limeOptions = { color: 'lime' }
  // const purpleOptions = { color: 'purple' }
  // const redOptions = { color: 'red' }

  // const center=[ 52.574166, 20.768333 ]
  const center=[ 53.16, 20.42];
  const zoom=11;

  //const { allPOIS } = useBwPois();
  // console.log("allPOIS >>>>>>>>>>", allPOIS );

  return(

    <MapContainer center={center} zoom={zoom} scrollWheelZoom={false} tap={false}>
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        {/* <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>         */}
        {/* <LayersControl.BaseLayer name='BlueMarble'>
          <TileLayer            
              attribution="&copy; NASA Blue Marble, image service by OpenGeo"
              url="https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//EPSG3857_500m/{z}/{y}/{x}.jpeg"            
          />
        </LayersControl.BaseLayer>         */}
      
        <LayersPois />
        <LayersTrails />

      </LayersControl>       
        
      {/* <MapConsumer>
        {(map) => {
          console.log('map center:', map.getCenter())
          // console.log('map position:', map.cen)
          return null
        }}
      </MapConsumer> */}
    </MapContainer>    
  );

}

// export default LeafletMap;

