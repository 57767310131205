import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Marker, Popup, LayersControl, LayerGroup, } from "react-leaflet";

const LayersPois = () => {
    return (
        <LayersControl.Overlay checked name="POIs">
            <LayerGroup>
                <StaticQuery
                    query={graphql`
                        query {
                            mlawa {
                                allPOIS {
                                    id
                                    uniqNumber
                                    name
                                    category
                                    createdAt
                                    description
                                    #subCategory
                                    bonus
                                    location {
                                        lat
                                        lng
                                    }
                                    images {
                                        id
                                        publicUrl
                                    }
                                }
                            }
                        }
                    `}
                    render={data => (
                        data.mlawa.allPOIS.map((index) => (
                            
                            index.location && (

                            //{/* <Marker position={[index.location.lat.toFixed(4), index.location.lng.toFixed(4)]}>*/}

                            <Marker key={index.uniqNumber} position={[index.location.lat, index.location.lng]}>
                                <Popup >
                                    <p>{index.name}</p>
                                    {/* {definePopup(index)} */}
                                    {/* <div>
                                        <image 
                                        src="https://bw1920-app-files.s3.eu-central-1.amazonaws.com/uploads/images/5fbe3966a578ce2f448efeb2.jpg"
                                        width="150"
                                        height="150"
                                        />
                                    </div> */}
                                    {index.images && (
                                        <div>
                                            <img
                                                // src="https://bw1920-app-files.s3.eu-central-1.amazonaws.com/uploads/images/5fbe3966a578ce2f448efeb2.jpg"
                                                src={index.images[0].publicUrl}
                                                // width="150"
                                                // height="150"
                                                alt={index.name}
                                            />
                                        </div>
                                        // <Img
                                        //     fixed={index.images[0].publicUrl}
                                        //     alt="Cat taking up an entire chair"
                                        // />
                                    )}
                                </Popup>
                            </Marker>
                            )


                        )
                        )
                    )}
                />
            </LayerGroup>
        </LayersControl.Overlay>
    )
}


export default LayersPois;